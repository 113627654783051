import { t } from "i18next"
import PageContainer from "../../../components/global/PageContainer"
import { Stack, TableCell } from "@mui/material"
import { colors } from "../../../services/config/colors"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import Table from "../../../components/global/Table"
import TableRow from "../../../components/global/TableRow"
import { useContext, useEffect, useMemo, useState } from "react"
import { MainContext } from "../../../controllers/main"
import Text from "../../../components/global/Text"
import { useSearchParams } from "react-router-dom"
import CompactFilters from "../../../components/global/CompactFilters"

const AllSdgs = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { topCategoriesList, categories } = useContext(MainContext)

  // tabs
  const [selectedTab, setSelectedTab] = useState<number>(
    searchParams.has("tab") ? parseInt(searchParams.get("tab")!) : 0
  )

  // update query param when selectedTab changes
  useEffect(() => {
    searchParams.set("tab", selectedTab.toString())
    setSearchParams(searchParams)
  }, [selectedTab])

  // table heading items
  const headingItems = useMemo(() => {
    return [
      <>
        {t("categories")}{" "}
        <span style={{ color: colors.textSecondary }}>
          (
          {
            topCategoriesList
              .filter((item) =>
                categories.some(
                  (category) => category.id === item.activityCategory
                )
              )
              .filter((item) =>
                selectedTab === 0
                  ? item.type === "episode"
                  : item.type === "action"
              ).length
          }
          )
        </span>
      </>,
      t("total_visualizations"),
    ]
  }, [topCategoriesList, selectedTab])

  return (
    <PageContainer
      title={t("all_categories")}
      breadcrumbs={[
        { title: t("education"), path: "/education" },
        { title: t("all_categories") },
      ]}
      withFilters={false}
      withTabs
      tabs={[t("episodes"), t("actions")]}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      paddingTop={0}
    >
      <CompactFilters />
      <FadeFromTop style={{ marginTop: 40 }}>
        <Table headingItems={headingItems}>
          {topCategoriesList
            .filter((item) =>
              categories.some(
                (category) => category.id === item.activityCategory
              )
            )
            .filter((item) =>
              selectedTab === 0
                ? item.type === "episode"
                : item.type === "action"
            )
            .map((item, index) => (
              <TableRow index={index} key={index}>
                <TableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{ gap: 20 }}
                  >
                    <Text fontSize={14} fontWeight={500}>
                      {index + 1}°
                    </Text>
                    <div
                      className="center"
                      style={{
                        width: "auto",
                        height: 30,
                        borderRadius: 4,
                        paddingInline: 8,
                        backgroundColor: categories.find(
                          (category) => category.id === item.activityCategory
                        )?.backTagColor,
                      }}
                    >
                      <Text
                        fontSize={12}
                        fontWeight={700}
                        color={
                          categories.find(
                            (category) => category.id === item.activityCategory
                          )?.foreColor
                        }
                      >
                        {
                          categories.find(
                            (category) => category.id === item.activityCategory
                          )?.name
                        }
                      </Text>
                    </div>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Text fontSize={14} fontWeight={500}>
                    {item.activityAmount}
                  </Text>
                </TableCell>
              </TableRow>
            ))}
        </Table>
      </FadeFromTop>
    </PageContainer>
  )
}

export default AllSdgs
