import { t } from "i18next"
import PageContainer from "../../../components/global/PageContainer"
import { Stack, TableCell } from "@mui/material"
import { colors } from "../../../services/config/colors"
import FadeFromTop from "../../../components/animations/FadeFromTop"
import Table from "../../../components/global/Table"
import TableRow from "../../../components/global/TableRow"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { MainContext } from "../../../controllers/main"
import Text from "../../../components/global/Text"
import { useSearchParams } from "react-router-dom"
import CompactFilters from "../../../components/global/CompactFilters"

const AllSdgs = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    dataLoading,
    topEpisodesList,
    topActionsList,
    topEpisodesListNextToken,
    topActionsListNextToken,
    loadMoreTopEpisodesItems,
    loadMoreTopActionsItems,
  } = useContext(MainContext)

  // tabs
  const [selectedTab, setSelectedTab] = useState<number>(
    searchParams.has("tab") ? parseInt(searchParams.get("tab")!) : 0
  )

  // update query param when selectedTab changes
  useEffect(() => {
    searchParams.set("tab", selectedTab.toString())
    setSearchParams(searchParams)
  }, [selectedTab])

  // table heading items
  const headingItems = useMemo(() => {
    return [
      <>
        {selectedTab === 0 ? t("episodes") : t("actions")}{" "}
        <span style={{ color: colors.textSecondary }}>
          ({selectedTab === 0 ? topEpisodesList.length : topActionsList.length})
        </span>
      </>,
      t("category"),
      selectedTab === 0 ? t("total_visualizations") : t("total_logs"),
    ]
  }, [topEpisodesList, topActionsList, selectedTab])

  // page container onScroll event to fetch new items on scroll
  const fetchingMoreItems = useRef<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)

  const onScrollEpisodes = async () => {
    const mainContainer = document.getElementById("page-container")!

    if (
      mainContainer.scrollTop + 50 >=
        mainContainer.scrollHeight - window.innerHeight &&
      !fetchingMoreItems.current &&
      topEpisodesListNextToken &&
      !dataLoading
    ) {
      fetchingMoreItems.current = true
      setLoadingMore(true)

      await loadMoreTopEpisodesItems()

      fetchingMoreItems.current = false
      setLoadingMore(false)
    }
  }

  const onScrollActions = async () => {
    const mainContainer = document.getElementById("page-container")!

    if (
      mainContainer.scrollTop + 50 >=
        mainContainer.scrollHeight - window.innerHeight &&
      !fetchingMoreItems.current &&
      topActionsListNextToken &&
      !dataLoading
    ) {
      fetchingMoreItems.current = true
      setLoadingMore(true)

      await loadMoreTopActionsItems()

      fetchingMoreItems.current = false
      setLoadingMore(false)
    }
  }

  return (
    <PageContainer
      title={t("all_content")}
      breadcrumbs={[
        { title: t("education"), path: "/education" },
        { title: t("all_content") },
      ]}
      withFilters={false}
      withTabs
      tabs={[t("episodes"), t("actions")]}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      tabsDisabled={loadingMore}
      onScroll={selectedTab === 0 ? onScrollEpisodes : onScrollActions}
      paddingTop={0}
    >
      <CompactFilters />
      <FadeFromTop style={{ marginTop: 40 }}>
        {selectedTab === 0 ? (
          <Table headingItems={headingItems} loading={loadingMore}>
            {topEpisodesList.map((item, index) => (
              <TableRow index={index} key={index}>
                <TableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{ gap: 20 }}
                  >
                    <Text fontSize={14} fontWeight={500}>
                      {index + 1}°
                    </Text>
                    <img
                      src={item.episode.image}
                      style={{
                        width: 30,
                        minWidth: 30,
                        height: 30,
                        borderRadius: 4,
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                      alt=""
                    />
                    <Text fontSize={14} fontWeight={500}>
                      {item.episode.translation.title}
                    </Text>
                  </Stack>
                </TableCell>
                <TableCell>
                  <div
                    className="center"
                    style={{
                      width: "fit-content",
                      height: 30,
                      borderRadius: 4,
                      paddingInline: 8,
                      backgroundColor: item.episode.category.backTagColor,
                    }}
                  >
                    <Text
                      fontSize={12}
                      fontWeight={700}
                      color={item.episode.category.foreColor}
                    >
                      {item.episode.category.name}
                    </Text>
                  </div>
                </TableCell>
                <TableCell>
                  <Text fontSize={14} fontWeight={500}>
                    {item.activityAmount}
                  </Text>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        ) : (
          <Table headingItems={headingItems} loading={loadingMore}>
            {topActionsList.map((item, index) => (
              <TableRow index={index} key={index}>
                <TableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{ gap: 20 }}
                  >
                    <Text fontSize={14} fontWeight={500}>
                      {index + 1}°
                    </Text>
                    <img
                      src={item.action.image}
                      style={{
                        width: 30,
                        minWidth: 30,
                        height: 30,
                        borderRadius: 4,
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                      alt=""
                    />
                    <Text fontSize={14} fontWeight={500}>
                      {item.action.document.title}
                    </Text>
                  </Stack>
                </TableCell>
                <TableCell>
                  <div
                    className="center"
                    style={{
                      width: "fit-content",
                      height: 30,
                      borderRadius: 4,
                      paddingInline: 8,
                      backgroundColor: item.action.category.backTagColor,
                    }}
                  >
                    <Text
                      fontSize={12}
                      fontWeight={700}
                      color={item.action.category.foreColor}
                    >
                      {item.action.category.name}
                    </Text>
                  </div>
                </TableCell>
                <TableCell>
                  <Text fontSize={14} fontWeight={500}>
                    {item.activityAmount}
                  </Text>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        )}
      </FadeFromTop>
    </PageContainer>
  )
}

export default AllSdgs
