import { t } from "i18next"
import { colors } from "../../services/config/colors"
import Text from "../global/Text"
import { Dispatch, SetStateAction, useContext, useEffect } from "react"
import { MainContext } from "../../controllers/main"
import { ButtonBase, Stack } from "@mui/material"
import months from "../../services/config/months"
import chevronLeftIcon from "../../assets/icons/chevron-left-small.svg"
import chevronRightIcon from "../../assets/icons/chevron-right-small.svg"
import { defaultTransition } from "../../services/config/constants"
import {
  getLastDayOfMonth,
  getMonday,
  getSunday,
} from "../../services/utils/utils"

const OverviewIntervalSelect = ({
  contentSelectedOption,
  contentInterval,
  setContentInterval,
}: {
  contentSelectedOption: number
  contentInterval: {
    start: Date
    end: Date
  }
  setContentInterval: Dispatch<
    SetStateAction<{
      start: Date
      end: Date
    }>
  >
}) => {
  const { overviewDataLoading, dateRange } = useContext(MainContext)

  // adjust interval based on global date range
  useEffect(() => {
    if (contentSelectedOption === 0) {
      const startDateToSet = dateRange.end
        ? getMonday(dateRange.end)
        : getMonday(new Date())
      const endDateToSet = dateRange.end
        ? getSunday(dateRange.end)
        : getSunday(new Date())

      setContentInterval({
        start: new Date(
          startDateToSet.getFullYear(),
          startDateToSet.getMonth(),
          startDateToSet.getDate(),
          new Date().getHours()
        ),
        end: new Date(
          endDateToSet.getFullYear(),
          endDateToSet.getMonth(),
          endDateToSet.getDate(),
          new Date().getHours()
        ),
      })
    } else if (contentSelectedOption === 1) {
      const startDateToSet = dateRange.end
        ? new Date(new Date(dateRange.end).setMonth(dateRange.end.getMonth()))
        : new Date(new Date().setMonth(new Date().getMonth()))

      setContentInterval({
        start: new Date(
          startDateToSet.getFullYear(),
          startDateToSet.getMonth(),
          1,
          new Date().getHours()
        ),
        end: new Date(
          dateRange.end
            ? dateRange.end.getFullYear()
            : new Date().getFullYear(),
          dateRange.end ? dateRange.end.getMonth() : new Date().getMonth(),
          getLastDayOfMonth(
            dateRange.end
              ? dateRange.end.getFullYear()
              : new Date().getFullYear(),
            dateRange.end ? dateRange.end.getMonth() : new Date().getMonth()
          ),
          new Date().getHours()
        ),
      })
    } else if (contentSelectedOption === 2) {
      setContentInterval({
        start: new Date(
          dateRange.start
            ? dateRange.start.getFullYear()
            : new Date().getFullYear(),
          0,
          1,
          new Date().getHours()
        ),
        end: new Date(
          dateRange.start
            ? dateRange.start.getFullYear()
            : new Date().getFullYear(),
          11,
          31,
          new Date().getHours()
        ),
      })
    }
  }, [contentSelectedOption, dateRange])

  return (
    <Stack direction="row" alignItems="center" gap={1} style={{ height: 20 }}>
      <ButtonBase
        disabled={
          overviewDataLoading ||
          (contentSelectedOption === 2 &&
            dateRange.start &&
            dateRange.start.getFullYear() >=
              contentInterval.start.getFullYear()) ||
          ((contentSelectedOption === 0 || contentSelectedOption === 1) &&
            dateRange.start &&
            dateRange.start >= contentInterval.start)
            ? true
            : false
        }
        style={{
          width: 20,
          minWidth: 20,
          height: 20,
          borderRadius: "100%",
        }}
        onClick={() => {
          if (contentSelectedOption === 0) {
            contentInterval.start = new Date(
              new Date(contentInterval.start).setDate(
                contentInterval.start.getDate() - 7
              )
            )
            contentInterval.end = new Date(
              new Date(contentInterval.end).setDate(
                contentInterval.end.getDate() - 7
              )
            )
          } else if (contentSelectedOption === 1) {
            contentInterval.start = new Date(
              new Date(contentInterval.start).setMonth(
                contentInterval.start.getMonth() - 1
              )
            )
            contentInterval.end = new Date(
              contentInterval.end.getFullYear(),
              contentInterval.end.getMonth() - 1,
              getLastDayOfMonth(
                contentInterval.end.getFullYear(),
                contentInterval.end.getMonth() - 1
              ),
              contentInterval.end.getHours()
            )
          } else if (contentSelectedOption === 2) {
            contentInterval.start = new Date(
              contentInterval.start.getFullYear() - 1,
              0,
              1,
              new Date().getHours()
            )
            contentInterval.end = new Date(
              contentInterval.end.getFullYear() - 1,
              11,
              31,
              new Date().getHours()
            )
          }

          setContentInterval({ ...contentInterval })
        }}
      >
        <img
          src={chevronLeftIcon}
          alt=""
          style={{
            transition: defaultTransition,
            opacity:
              overviewDataLoading ||
              (contentSelectedOption === 2 &&
                dateRange.start &&
                dateRange.start.getFullYear() >=
                  contentInterval.start.getFullYear()) ||
              ((contentSelectedOption === 0 || contentSelectedOption === 1) &&
                dateRange.start &&
                dateRange.start >= contentInterval.start)
                ? 0.4
                : 1,
          }}
        />
      </ButtonBase>
      {contentSelectedOption === 0 ? (
        <Text
          fontSize={12}
          lineHeight="18px"
          fontWeight={500}
          color={colors.primary}
        >
          {t("week")}:{" "}
          {`${contentInterval.start.getDate()} ${t(
            months[contentInterval.start.getMonth()]
          ).slice(0, 3)}`}{" "}
          -{" "}
          {`${contentInterval.end.getDate()} ${t(
            months[contentInterval.end.getMonth()]
          ).slice(0, 3)} ${contentInterval.end.getFullYear()}`}
        </Text>
      ) : contentSelectedOption === 1 ? (
        <Text
          fontSize={12}
          lineHeight="18px"
          fontWeight={500}
          color={colors.primary}
        >
          {t("month")}: {t(months[contentInterval.end.getMonth()])}{" "}
          {contentInterval.end.getFullYear()}
        </Text>
      ) : contentSelectedOption === 2 ? (
        <Text
          fontSize={12}
          lineHeight="18px"
          fontWeight={500}
          color={colors.primary}
        >
          {t("year")}: {contentInterval.start.getFullYear()}
        </Text>
      ) : null}
      <ButtonBase
        disabled={
          overviewDataLoading ||
          (contentSelectedOption === 2 &&
            dateRange.end &&
            dateRange.end.getFullYear() <= contentInterval.end.getFullYear()) ||
          (contentSelectedOption === 2 &&
            !dateRange.end &&
            new Date().getFullYear() <= contentInterval.end.getFullYear()) ||
          ((contentSelectedOption === 0 || contentSelectedOption === 1) &&
            dateRange.end &&
            dateRange.end <= contentInterval.end) ||
          ((contentSelectedOption === 0 || contentSelectedOption === 1) &&
            !dateRange.end &&
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            ) <=
              new Date(
                contentInterval.end.getFullYear(),
                contentInterval.end.getMonth(),
                contentInterval.end.getDate()
              ))
            ? true
            : false
        }
        style={{
          width: 20,
          minWidth: 20,
          height: 20,
          borderRadius: "100%",
        }}
        onClick={() => {
          if (contentSelectedOption === 0) {
            contentInterval.start = new Date(
              new Date(contentInterval.start).setDate(
                contentInterval.start.getDate() + 7
              )
            )
            contentInterval.end = new Date(
              new Date(contentInterval.end).setDate(
                contentInterval.end.getDate() + 7
              )
            )
          } else if (contentSelectedOption === 1) {
            contentInterval.start = new Date(
              new Date(contentInterval.start).setMonth(
                contentInterval.start.getMonth() + 1
              )
            )
            contentInterval.end = new Date(
              contentInterval.end.getFullYear(),
              contentInterval.end.getMonth() + 1,
              getLastDayOfMonth(
                contentInterval.end.getFullYear(),
                contentInterval.end.getMonth() + 1
              ),
              contentInterval.end.getHours()
            )
          } else if (contentSelectedOption === 2) {
            contentInterval.start = new Date(
              contentInterval.start.getFullYear() + 1,
              0,
              1,
              new Date().getHours()
            )
            contentInterval.end = new Date(
              contentInterval.end.getFullYear() + 1,
              11,
              31,
              new Date().getHours()
            )
          }

          setContentInterval({ ...contentInterval })
        }}
      >
        <img
          src={chevronRightIcon}
          alt=""
          style={{
            transition: defaultTransition,
            opacity:
              overviewDataLoading ||
              (contentSelectedOption === 2 &&
                dateRange.end &&
                dateRange.end.getFullYear() <=
                  contentInterval.end.getFullYear()) ||
              (contentSelectedOption === 2 &&
                !dateRange.end &&
                new Date().getFullYear() <=
                  contentInterval.end.getFullYear()) ||
              ((contentSelectedOption === 0 || contentSelectedOption === 1) &&
                dateRange.end &&
                dateRange.end <= contentInterval.end) ||
              ((contentSelectedOption === 0 || contentSelectedOption === 1) &&
                !dateRange.end &&
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  new Date().getDate()
                ) <=
                  new Date(
                    contentInterval.end.getFullYear(),
                    contentInterval.end.getMonth(),
                    contentInterval.end.getDate()
                  ))
                ? 0.4
                : 1,
          }}
        />
      </ButtonBase>
    </Stack>
  )
}

export default OverviewIntervalSelect
