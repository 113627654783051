import { ButtonBase, Stack, useMediaQuery, useTheme } from "@mui/material"
import { colors } from "../../services/config/colors"
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react"
import Title from "./Title"
import searchPrimaryIcon from "../../assets/icons/search-primary.svg"
import settingsPrimaryIcon from "../../assets/icons/settings-primary.svg"
import bellPrimaryIcon from "../../assets/icons/bell-primary.svg"
import Button from "./Button"
import exportPrimaryIcon from "../../assets/icons/export-primary.svg"
import Filter from "./Filter"
import { FilterType } from "../../services/config/enum"
import { MainContext } from "../../controllers/main"
import {
  availableLanguages,
  defaultTransition,
  showPageContainerIcons,
} from "../../services/config/constants"
import FilterTag from "./FilterTag"
import { AnimatePresence, motion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import chevronLeftPrimaryIcon from "../../assets/icons/chevron-left-primary.svg"
import Tabs from "./Tabs"
import DateRangePicker from "./DateRangePicker"
import i18next, { t } from "i18next"
import { toCanvas } from "html-to-image"
import { jsPDF } from "jspdf"
import { AuthContext } from "../../controllers/auth"
import slugify from "slugify"
import menuPrimary from "../../assets/icons/menu-primary.png"
import SelectComplex from "./SelectComplex"
import { client } from "../.."

const PageContainer = ({
  title,
  breadcrumbs,
  withFilters = true,
  withDatePicker = true,
  withExportButton = true,
  paddingTop = 48,
  withTabs = false,
  tabs,
  selectedTab,
  setSelectedTab,
  tabsDisabled = false,
  children,
  variant = "normal",
  onScroll,
}: {
  title: string
  breadcrumbs: { title: string; path?: string }[]
  withFilters?: boolean
  withDatePicker?: boolean
  withExportButton?: boolean
  paddingTop?: 0 | 24 | 48
  withTabs?: boolean
  tabs?: string[]
  selectedTab?: number
  setSelectedTab?: Dispatch<SetStateAction<number>>
  tabsDisabled?: boolean
  children?: ReactNode
  variant?: "normal" | "compact"
  onScroll?: () => void
}) => {
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"))
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"))
  const navigate = useNavigate()
  const { team, setModalMenuOpen, getUserTeamsList } = useContext(AuthContext)
  const { selectedFilters, setSelectedFilters, dataLoading, getData } =
    useContext(MainContext)

  // export page
  const screenshotPadding = 30

  const containerRef = useRef<HTMLDivElement>(null)
  const [exporting, setExporting] = useState<boolean>(false)

  const exportPage = useCallback(() => {
    if (containerRef.current === null) {
      return
    }

    setExporting(true)

    toCanvas(containerRef.current, {
      cacheBust: false,
      backgroundColor: colors.background,
      width: containerRef.current.clientWidth + screenshotPadding * 2,
      height: containerRef.current.clientHeight + screenshotPadding * 2,
      style: { padding: `${screenshotPadding}px` },
    })
      .then((canvas) => {
        const doc = new jsPDF({
          orientation: "p",
          unit: "px",
          format: [canvas.width, canvas.height],
        })
        doc.addImage(canvas, "PNG", 0, 0, canvas.width, canvas.height)

        doc.save(
          `${slugify(team!.title.toLowerCase())}_report_${
            new Date().toISOString().split("T")[0]
          }.pdf`
        )

        setExporting(false)
      })
      .catch((err) => {
        console.log(err)

        setExporting(false)
      })
  }, [containerRef, team])

  // filter remove timeoutId
  const filterTimeoutId = useRef<any>(null)

  return (
    <Stack
      id="page-container"
      alignItems="center"
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100%",
        maxHeight: "100%",
        backgroundColor: colors.background,
        padding: matchesSM ? 24 : 12,
        overflowY: "scroll",
        overflowX: "hidden",
      }}
      onScroll={onScroll}
    >
      <div ref={containerRef} style={{ width: "100%", maxWidth: 1200 }}>
        {/* breadcrumbs and icons */}
        <div
          style={{
            width: "100%",
            height: 38,
            minHeight: 38,
            position: "relative",
          }}
        >
          {/* breadcrumbs */}
          <Stack direction="row" alignItems="center" gap={1}>
            <ButtonBase disableRipple disabled>
              <Title
                fontSize={14}
                color={colors.textSecondary}
                style={{ letterSpacing: "1px" }}
                shouldLoad
              >
                {team?.title}
              </Title>
            </ButtonBase>
            {breadcrumbs.map((item, index) => (
              <Stack key={index} direction="row" alignItems="center" gap={1}>
                <Title
                  fontSize={14}
                  color={colors.textSecondary}
                  style={{ letterSpacing: "1px" }}
                >
                  /
                </Title>
                <ButtonBase
                  disableRipple
                  disabled={!item.path}
                  onClick={() => {
                    if (item.path) {
                      navigate(item.path)
                    }
                  }}
                >
                  <Title
                    fontSize={14}
                    color={colors.textSecondary}
                    style={{ letterSpacing: "1px" }}
                  >
                    {item.title}
                  </Title>
                </ButtonBase>
              </Stack>
            ))}
          </Stack>
          {/* language select */}
          <SelectComplex
            options={availableLanguages.map((item) => {
              return {
                ...item,
                label: t(item.label),
              }
            })}
            selectedOption={{
              ...availableLanguages.find(
                (item) => item.id === i18next.language
              )!,
              label: t(
                availableLanguages.find((item) => item.id === i18next.language)!
                  .label
              ),
            }}
            onOptionChange={async (newOption) => {
              if (newOption.id !== i18next.language) {
                // change language in i18n and localStorage
                i18next.changeLanguage(newOption.id)
                localStorage.setItem("language", newOption.id)

                // empty apollo client cache (problem with lang change)
                await client.clearStore()

                // get all data with the new language
                getData()
                getUserTeamsList()
              }
            }}
            style={{
              width: matchesSM ? 160 : 110,
              height: 38,
              position: "absolute",
              top: 0,
              right: 0,
            }}
            popoverWidth={matchesSM ? 160 : 110}
          />
          {/* icons */}
          {variant === "normal" && showPageContainerIcons ? (
            <Stack
              direction="row"
              gap={2}
              style={{ position: "absolute", right: 0, top: 0 }}
            >
              <ButtonBase
                disabled
                style={{
                  width: 38,
                  height: 38,
                  borderRadius: 10,
                  backgroundColor: colors.backgroundWhite,
                }}
              >
                <img
                  src={searchPrimaryIcon}
                  style={{ width: 15, height: 15, opacity: 0.4 }}
                />
              </ButtonBase>
              <ButtonBase
                disabled
                style={{
                  width: 38,
                  height: 38,
                  borderRadius: 10,
                  backgroundColor: colors.backgroundWhite,
                }}
              >
                <img
                  src={settingsPrimaryIcon}
                  style={{ width: 15, height: 15, opacity: 0.4 }}
                />
              </ButtonBase>
              <ButtonBase
                disabled
                style={{
                  width: 38,
                  height: 38,
                  borderRadius: 10,
                  backgroundColor: colors.backgroundWhite,
                }}
              >
                <img
                  src={bellPrimaryIcon}
                  style={{ width: 15, height: 15, opacity: 0.4 }}
                />
              </ButtonBase>
            </Stack>
          ) : null}
        </div>
        {!matchesLG && (
          <Button
            style={{ width: 40, height: 40 }}
            onClick={() => {
              setModalMenuOpen(true)
            }}
          >
            <img src={menuPrimary} style={{ width: 16, height: 16 }} alt="" />
          </Button>
        )}
        {/* header */}
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          style={{
            width: "100%",
            height: 40,
            position: "relative",
            marginTop: matchesLG ? 24 : 12,
          }}
        >
          {breadcrumbs.length > 1 && (
            <ButtonBase
              style={{
                width: 32.5,
                minWidth: 32.5,
                height: 32.5,
                borderRadius: "100%",
              }}
              onClick={() => {
                const path = window.location.pathname
                navigate(path.slice(0, path.lastIndexOf("/")))
              }}
            >
              <img src={chevronLeftPrimaryIcon} alt="" />
            </ButtonBase>
          )}
          <AnimatePresence>
            <div style={{ width: "auto", height: 32.5, position: "relative" }}>
              <motion.div
                key={title}
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                transition={{
                  scale: { duration: 0.4, type: "spring" },
                  opacity: { duration: 0.1 },
                }}
                style={{ position: "absolute" }}
              >
                <Title
                  color={colors.primary}
                  fontWeight={900}
                  lineHeight="32.5px"
                  style={{
                    textTransform: "uppercase",
                    whiteSpace: "nowrap",
                  }}
                  ellipsis
                >
                  {title}
                </Title>
              </motion.div>
            </div>
          </AnimatePresence>
          <Stack
            direction="row"
            gap={matchesSM ? 2 : 1.5}
            style={{ height: 40, position: "absolute", right: 0 }}
          >
            {withDatePicker && (
              <DateRangePicker primary={variant === "normal"} />
            )}
            {(variant === "compact" || !matchesSM) && withExportButton ? (
              <Button
                style={{ width: 40 }}
                loading={exporting}
                onClick={exportPage}
              >
                <img
                  src={exportPrimaryIcon}
                  style={{ width: 15, height: 15 }}
                  alt=""
                />
              </Button>
            ) : null}
            {variant === "normal" && withExportButton && matchesSM ? (
              <Button
                loading={exporting}
                onClick={exportPage}
                style={{ width: 160 }}
              >
                <Stack direction="row" alignItems="center" style={{ gap: 10 }}>
                  <img
                    src={exportPrimaryIcon}
                    style={{ width: 15, height: 15 }}
                    alt=""
                  />
                  <div>{t("export")}</div>
                </Stack>
              </Button>
            ) : null}
          </Stack>
        </Stack>
        {/* tabs */}
        {withTabs &&
        tabs &&
        tabs.length &&
        selectedTab !== undefined &&
        setSelectedTab ? (
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            variant="slim"
            disabled={tabsDisabled}
            style={{ marginTop: 30 }}
          />
        ) : null}
        {/* filters */}
        {withFilters && (
          <Stack
            direction="row"
            gap={2}
            style={{ width: "100%", height: 74, marginTop: 32 }}
          >
            <Filter
              type={FilterType.location}
              selectedOptions={selectedFilters}
              setSelectedOptions={setSelectedFilters}
            />
            <Filter
              type={FilterType.group}
              selectedOptions={selectedFilters}
              setSelectedOptions={setSelectedFilters}
            />
            {/* <Filter
              type={FilterType.gender}
              selectedOptions={selectedFilters}
              setSelectedOptions={setSelectedFilters}
            />
            <Filter
              type={FilterType.age}
              selectedOptions={selectedFilters}
              setSelectedOptions={setSelectedFilters}
            /> */}
          </Stack>
        )}
        {/* active filters */}
        {withFilters && (
          <Stack
            style={{
              maxHeight: selectedFilters.length
                ? selectedFilters.length > 1
                  ? 113
                  : 65
                : 0,
              marginTop: selectedFilters.length ? 24 : 0,
              opacity: selectedFilters.length ? 1 : 0,
              transition: defaultTransition,
              overflow: "hidden",
            }}
          >
            <Stack
              direction="row"
              style={{ width: 320, height: 17, position: "relative" }}
            >
              <Title fontSize={14} lineHeight="17px">
                {t("showing")}
              </Title>
              <ButtonBase
                disabled={dataLoading}
                disableRipple
                style={{ position: "absolute", right: 0, borderRadius: 4 }}
                onClick={() => {
                  selectedFilters.splice(0, selectedFilters.length)
                  setSelectedFilters([...selectedFilters])
                  getData()
                }}
              >
                <Title
                  fontSize={14}
                  lineHeight="17px"
                  color={colors.primary}
                  style={{
                    transition: defaultTransition,
                    opacity: dataLoading ? 0.4 : 1,
                  }}
                >
                  {t("clear_all")}
                </Title>
              </ButtonBase>
            </Stack>
            <Stack
              direction="row"
              gap={2}
              flexWrap="wrap"
              style={{
                width: "100%",
                height: "auto",
                minHeight: 32,
                marginTop: 16,
              }}
            >
              {selectedFilters.map((filter, index) => (
                <FilterTag
                  key={filter.id}
                  name={filter.label}
                  onRemove={() => {
                    if (filterTimeoutId.current) {
                      clearTimeout(filterTimeoutId.current)
                    }

                    selectedFilters.splice(index, 1)
                    setSelectedFilters([...selectedFilters])

                    filterTimeoutId.current = setTimeout(getData, 1000)
                  }}
                />
              ))}
            </Stack>
          </Stack>
        )}
        {/* page content */}
        <div
          style={{
            paddingTop: paddingTop,
          }}
        >
          <div>{children}</div>
        </div>
      </div>
    </Stack>
  )
}

export default PageContainer
