import { Skeleton, Stack, useMediaQuery, useTheme } from "@mui/material"
import { colors } from "../../services/config/colors"
import Title from "../global/Title"
import Text from "../global/Text"
import { t } from "i18next"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"

const EmissionsCard = ({
  icon,
  title,
  color,
  amount,
  percentage,
}: {
  icon: string
  title: string
  color: string
  amount?: number
  percentage?: number
}) => {
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"))
  const { dataLoading } = useContext(MainContext)

  return (
    <Stack
      direction="row"
      style={{
        width: "100%",
        height: 100,
        borderRadius: 8,
        backgroundColor: colors.background,
        border: "1px solid " + colors.primary,
        position: "relative",
        paddingInline: 16,
        paddingTop: 12,
        gap: 30,
      }}
    >
      {/* white background */}
      {matchesSM && (
        <div
          style={{
            width: 76,
            height: "100%",
            backgroundColor: icon ? colors.backgroundWhite : colors.background,
            position: "absolute",
            top: 0,
            left: 0,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
          }}
        />
      )}
      {/* icon */}
      {matchesSM && (
        <div
          className="center"
          style={{
            width: 44,
            height: 44,
            borderRadius: 10,
            backgroundColor: dataLoading ? "white" : color,
            position: "relative",
          }}
        >
          {dataLoading ? (
            <Skeleton
              variant="rounded"
              style={{ width: "100%", height: "100%", borderRadius: 10 }}
            />
          ) : (
            <img src={icon} style={{ height: 20, maxWidth: 28 }} alt="" />
          )}
        </div>
      )}
      {/* data */}
      <Stack>
        <Title fontSize={15} lineHeight="15px" shouldLoad>
          {title}
        </Title>
        <Title
          fontSize={22}
          lineHeight="27px"
          style={{ marginTop: 15 }}
          shouldLoad
          skeletonWidth={60}
        >
          {amount !== undefined
            ? amount.toString().replace(".", ",")
            : t("no_data")}
        </Title>
        <Text fontSize={12} lineHeight="12px" style={{ marginTop: 4 }}>
          {t("tons_of_co2e")}
        </Text>
      </Stack>
      {percentage !== undefined && (
        <Title
          fontSize={13}
          color={colors.primary}
          style={{ position: "absolute", top: 12, right: 16 }}
          shouldLoad
          skeletonWidth={30}
        >
          {percentage.toString().replace(".", ",")}%
        </Title>
      )}
    </Stack>
  )
}

export default EmissionsCard
