import { gql } from "@apollo/client"

export const dashboardUserTeamsList = gql`
  query DashboardUserTeamsListV2($input: DashboardUserTeamsListInput) {
    dashboardUserTeamsListV2(input: $input) {
      items {
        team {
          id
          isPrimary
          document {
            lang
            title
            logo
          }
        }
      }
      nextToken
    }
  }
`

export const dashboardTeamGroupList = gql`
  query DashboardTeamGroupListV2($input: DashboardTeamGroupListInput) {
    dashboardTeamGroupListV2(input: $input) {
      items {
        groupId
        name
      }
    }
  }
`

export const dashboardTeamCountriesList = gql`
  query DashboardTeamCountriesListV2($input: DashboardTeamCountriesListInput) {
    dashboardTeamCountriesListV2(input: $input) {
      items {
        country
      }
      nextToken
    }
  }
`

export const dashboardTotalUsersGet = gql`
  query DashboardTotalUsersGetV2($input: DashboardTotalUsersGetInput) {
    dashboardTotalUsersGetV2(input: $input) {
      users
      activeUsersPercentage
    }
  }
`

export const dashboardAverageUserGet = gql`
  query DashboardAverageUserGetV2($input: DashboardAverageUserGetInput) {
    dashboardAverageUserGetV2(input: $input) {
      country
      percentage
      users
    }
  }
`

export const dashboardMostActiveUserGet = gql`
  query DashboardMostActiveUserGetV2($input: DashboardMostActiveUserGetInput) {
    dashboardMostActiveUserGetV2(input: $input) {
      sub
      activityHour
      avgActionsComparisonPercentage
      avgEpisodesComparisonPercentage
      country
      sameActiveHourPercentage
      userActions
      userEpisodes
      user {
        first_name
        last_name
        country
        uid
        nickname
        profileImage
        sub
      }
    }
  }
`

export const dashboardChallengeGet = gql`
  query DashboardChallengeGetV2($input: DashboardChallengeGetInput) {
    dashboardChallengeGetV2(input: $input) {
      avgDailyMetrics
      challengeUsers
      challengeMetric
      daysTillTargetReached
      endsAt
      name
      reachedMetrics
      reachedPercentage
      startsAt
      targetAmount
      challenge {
        document {
          lang
          title
          image
        }
        metric {
          name
          unit
        }
      }
    }
  }
`

export const dashboardChallengeLeaderboardGet = gql`
  query DashboardChallengeLeaderboardGetV2(
    $input: DashboardChallengeLeaderboardGetInput
  ) {
    dashboardChallengeLeaderboardGetV2(input: $input) {
      items {
        previousWeekUserPosition
        sub
        userPoints
        userPointsDeltaPercentage
        userPosition
        userPositionDelta
        user {
          first_name
          last_name
          country
          uid
          nickname
          profileImage
          sub
        }
      }
      nextToken
    }
  }
`

export const dashboardActivityOverviewList = gql`
  query DashboardActivityOverviewListV2(
    $input: DashboardActivityOverviewListInput
  ) {
    dashboardActivityOverviewListV2(input: $input) {
      items {
        activityAmount
        activityDate
        activityType
        avgActivitiesDeltaPercentage
        avgActivityAmount
      }
      nextToken
    }
  }
`

export const dashboardActivitiesByCategoryList = gql`
  query DashboardActivitiesByCategoryListV2(
    $input: DashboardActivitiesByCategoryListInput
  ) {
    dashboardActivitiesByCategoryListV2(input: $input) {
      items {
        activityAmount
        activityCategory
        activityType
      }
      nextToken
    }
  }
`

export const dashboardSavingsGet = gql`
  query DashboardSavingsGetV2($input: DashboardSavingsGetInput) {
    dashboardSavingsGetV2(input: $input) {
      bathTubSaving
      co2Saving
      dishWasherSaving
      energySaving
      phoneChargeSaving
      waterSaving
    }
  }
`

export const dashboardFootprintOverviewGet = gql`
  query DashboardFootprintOverviewGetV2(
    $input: DashboardFootprintOverviewGetInput
  ) {
    dashboardFootprintOverviewGetV2(input: $input) {
      avgEmissionAmount
      footprintUsers
      maxEmissionAmount
      minEmissionAmount
      parisAgreementEmissionAmount
      parisAgreementEmissionDeltaPercentage
      totalUsers
      worldEmissionAmount
      worldEmissionDeltaPercentage
    }
  }
`

export const dashboardFootprintBreakdownGet = gql`
  query DashboardFootprintBreakdownGetV2(
    $input: DashboardFootprintBreakdownGetInput
  ) {
    dashboardFootprintBreakdownGetV2(input: $input) {
      items {
        category
        categoryEmissionAmount
        categoryEmissionPercentage
        totalEmissionAmount
        totalEmissionAsLaptopCharges
        totalEmissionAsLondonNYFlights
        totalEmissionAsTrees
      }
    }
  }
`

export const dashboardFootprintBreakdownByGroupGet = gql`
  query DashboardFootprintBreakdownByGroupGetV2(
    $input: DashboardFootprintBreakdownByGroupGetInput
  ) {
    dashboardFootprintBreakdownByGroupGetV2(input: $input) {
      items {
        category
        categoryEmissionAmount
        categoryEmissionPercentage
        groupId
      }
      nextToken
    }
  }
`

export const dashboardTopSdgsList = gql`
  query DashboardTopSdgsListV2($input: DashboardTopSdgsListInput) {
    dashboardTopSdgsListV2(input: $input) {
      items {
        activityAmount
        activitySdg
        position
        type
      }
      nextToken
    }
  }
`

export const dashboardTopCategoriesList = gql`
  query DashboardTopCategoriesListV2($input: DashboardTopCategoriesListInput) {
    dashboardTopCategoriesListV2(input: $input) {
      items {
        activityAmount
        activityCategory
        position
        type
      }
      nextToken
    }
  }
`

export const dashboardTopContentList = gql`
  query DashboardTopContentListV2($input: DashboardTopContentListInput) {
    dashboardTopContentListV2(input: $input) {
      items {
        activityAmount
        activityCategory
        activityName
        position
        type
        action {
          id
          image
          document {
            title
          }
          category {
            id
            code
            lang
            name
            backColor
            backTagColor
            foreColor
          }
        }
        episode {
          id
          image
          translation {
            title
          }
          category {
            id
            code
            lang
            name
            backColor
            backTagColor
            foreColor
          }
        }
      }
      nextToken
    }
  }
`

export const dashboardCompletedEpisodesList = gql`
  query DashboardCompletedEpisodesListV2(
    $input: DashboardCompletedEpisodesListInput
  ) {
    dashboardCompletedEpisodesListV2(input: $input) {
      items {
        activityPartitionType
        partitionAmount
        partitionType
        totalAmount
      }
      nextToken
    }
  }
`

export const categoriesList = gql`
  query CategoriesList($input: ListCategoriesInput) {
    categoriesList(input: $input) {
      items {
        id
        code
        lang
        name
        backTagColor
        backColor
        foreColor
      }
    }
  }
`

export const sdgsList = gql`
  query SdgsList($input: ListSdgsInput) {
    sdgsList(input: $input) {
      items {
        id
        code
        lang
        name
        image
      }
    }
  }
`

export const dashboardTotalEducationHoursGet = gql`
  query DashboardTotalEducationHoursGetV2(
    $input: DashboardTotalEducationHoursGetInput
  ) {
    dashboardTotalEducationHoursGetV2(input: $input) {
      avgEducationHoursPerUser
      totalEducationHours
    }
  }
`

export const dashboardFootprintCategoryBreakdownGet = gql`
  query DashboardFootprintCategoryBreakdownGetV2(
    $input: DashboardFootprintCategoryBreakdownGetInput
  ) {
    dashboardFootprintCategoryBreakdownGetV2(input: $input) {
      items {
        category
        sector
        sectorEmissionAmount
        sectorEmissionPercentage
        categoryEmissionAmount
      }
    }
  }
`

export const dashboardTotalEducationHoursUsersGet = gql`
  query DashboardTotalEducationHoursUsersGetV2(
    $input: DashboardTotalEducationHoursUsersGetInput
  ) {
    dashboardTotalEducationHoursUsersGetV2(input: $input) {
      items {
        totalEducationHours
        user {
          first_name
          last_name
          country
          uid
          nickname
          profileImage
          sub
        }
      }
      nextToken
    }
  }
`
