import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  BarElement,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import { colors } from "../../services/config/colors"
import "../../styles/journeysAndEpisodesChart.css"
import ChartDataItem from "../../models/chartDataItem"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"
import { Skeleton } from "@mui/material"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip
)

const BarChartMultiple = ({
  data,
  xTicksFontSize = 10,
  xTicksFontWeight = 700,
  maxBarThickness = 65,
  xTicksDisplay = true,
}: {
  data: ChartDataItem[][]
  xTicksFontSize?: number
  xTicksFontWeight?: 400 | 500 | 600 | 700
  maxBarThickness?: number
  xTicksDisplay?: boolean
}) => {
  const { dataLoading } = useContext(MainContext)

  // data for chart
  const chartData = {
    labels: data[0].map((item) =>
      item.label.length > 19 ? item.label.slice(0, 19) + "..." : item.label
    ),
    datasets: data.map((item, index) => {
      return {
        label: `Dataset ${index + 1}`,
        data: item.map((item2) => item2.value),
        maxBarThickness: maxBarThickness,
        backgroundColor: item.map((item2) => item2.backgroundColor),
        colors: item.some((item2) => !item2.color)
          ? item.map((item2) => colors.text)
          : item.map((item2) => item2.color),
        barPercentage: 0.75,
        categoryPercentage: 0.75,
      }
    }),
  }

  return dataLoading ? (
    <Skeleton variant="rounded" style={{ width: "100%", height: "100%" }} />
  ) : (
    <Bar
      style={{ maxWidth: "100%" }}
      options={{
        events: [],
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            border: {
              color: colors.chartsGrid,
            },
            grid: {
              display: false,
            },
            ticks: {
              color: (value) => {
                return chartData.datasets[0].colors[value.index]
              },
              font: {
                family: "Satoshi",
                size: xTicksFontSize,
                weight: xTicksFontWeight,
              },
              maxRotation: 0,
              display: xTicksDisplay,
            },
          },
          y: {
            min: 0,
            border: {
              width: 0,
            },
            grid: {
              color: colors.chartsGrid,
              tickWidth: 0,
            },
            ticks: {
              color: colors.text,
              font: {
                family: "Satoshi",
                size: 14,
              },
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          bar: {
            borderRadius: 6,
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      }}
      data={chartData}
    />
  )
}

export default BarChartMultiple
