import { Skeleton, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import Title from "./Title"
import { CSSProperties, useContext } from "react"
import { t } from "i18next"
import { MainContext } from "../../controllers/main"

const PointsBadge = ({
  points,
  style,
}: {
  points: number
  style?: CSSProperties
}) => {
  const { dataLoading } = useContext(MainContext)

  return dataLoading ? (
    <Skeleton
      variant="rectangular"
      style={{ width: 50, height: 22, borderRadius: 3.6, ...style }}
    />
  ) : (
    <Stack
      justifyContent="center"
      style={{
        width: "auto",
        height: 22,
        paddingInline: 5.5,
        borderRadius: 3.6,
        border: "1.5px solid " + colors.disabled,
        backgroundColor: colors.background,
        ...style,
      }}
    >
      <Title fontSize={14} color={colors.primary}>
        {points} {t("pt")}
      </Title>
    </Stack>
  )
}

export default PointsBadge
